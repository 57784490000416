@use "sass:map";
@use "@angular/material" as mat;
@use "/src/app//components//login-or-register/login-or-register-theme" as
  login-or-register-theme;
@use "/src/app//components//forgot-password/forgot-password-theme" as
  forgot-password-theme;
@use "/src/app//components//reset-password/reset-password-theme" as
  reset-password-theme;
@tailwind base;
@tailwind components;
@tailwind utilities;
@include mat.elevation-classes();
@include mat.app-background();

$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);
$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

$ucla-blue-palette: (
  50: #7fafdc,
  100: #6fa5d8,
  200: #5f9bd3,
  300: #4f91cf,
  400: #3f87ca,
  500: #3378b8,
  600: #3072b0,
  700: #2c68a0,
  800: #275e90,
  900: #235380,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
  ),
);

$mycopper-palette: (
  50: #dcac7f,
  100: #d8a16f,
  200: #d3975f,
  300: #cf8d4f,
  400: #ca823f,
  500: #b87333,
  600: #b06e30,
  700: #a0642c,
  800: #905a27,
  900: #805023,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $dark-primary-text,
    900: $light-primary-text,
  ),
);

$fire-brick-palette: (
  50: #e44e5a,
  100: #e2364a,
  200: #df2a39,
  300: #d5202f,
  400: #c31d2b,
  500: #b11b27,
  600: #931621,
  700: #662a2a,
  800: #7c131b,
  900: #6a1017,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $light-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
  ),
);

$primary-color: mat.m2-define-palette($ucla-blue-palette);
$accent-color: mat.m2-define-palette($mycopper-palette);
$warn-color: mat.m2-define-palette($fire-brick-palette);

$my-typography: mat.m2-define-typography-config(
  $font-family: "Noto Sans",
);

// Define a light theme
$light-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $primary-color,
      accent: $accent-color,
      warn: $warn-color,
    ),
    typography: $my-typography,
    density: 0,
  )
);

@include mat.all-component-themes($light-theme);
@include mat.all-component-typographies($my-typography);
@include login-or-register-theme.theme($light-theme);
@include forgot-password-theme.theme($light-theme);
@include reset-password-theme.theme($light-theme);

body {
  width: 100dvw;
  height: 100dvh;
  margin: 0;
  scrollbar-color: mat.m2-get-color-from-palette($primary-color, 500) #ccc;
  scrollbar-width: auto;

  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  ::-webkit-scrollbar-thumb {
    background: mat.m2-get-color-from-palette($primary-color, 500);
    border-radius: 5px;
  }
}

a {
  color: mat.m2-get-color-from-palette($primary-color, 500);
}
// Remove spinners form number inputs
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

fieldset {
  padding: 1rem;
  border: solid 1px mat.m2-get-color-from-palette($accent-color, 500);
  border-radius: 0.25rem;

  legend {
    color: mat.m2-get-color-from-palette($accent-color, 500);
  }
}

// An empty div to reserve space for a progress bar, so that content doesn't move around
.reserved-space {
  height: var(--mdc-linear-progress-track-height, 4px);
}

.mat-mdc-row:hover .mat-mdc-cell {
  background-color: mat.m2-get-color-from-palette($primary-color, 100);
}

.logo {
  width: 90px;
  height: 60px;
  display: inline-block;
}

.error-text {
  color: mat.m2-get-color-from-palette($warn-color, 500);
}

// fix conflict with tailwind borders
.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field
  .mdc-notched-outline__notch {
  border-right-style: hidden;
}

.dialog-form-grid {
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  overflow: auto;
}

.dialog-form-grid > .mat-mdc-dialog-content {
  max-height: 100%;
}

.full-screen-dialog .mat-mdc-dialog-surface {
  border-radius: 0 0 0 0;
}

// smaller icons
@mixin MatIconButtonSize($name, $buttonSize, $iconSize) {
  .mat-#{$name}-icon-button {
    width: $buttonSize !important;
    height: $buttonSize !important;
    padding: 0px !important;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;

    & > *[role="img"] {
      width: $iconSize;
      height: $iconSize;
      font-size: $iconSize;

      svg {
        width: $iconSize;
        height: $iconSize;
      }
    }

    .mat-mdc-button-touch-target {
      width: $buttonSize !important;
      height: $buttonSize !important;
    }
  }
}

@mixin MatIconSize($name, $iconSize) {
  .mat-icon.mat-#{$name}-icon {
    width: $iconSize;
    height: $iconSize;
    font-size: $iconSize;

    svg {
      width: $iconSize;
      height: $iconSize;
    }
  }
}

@include MatIconButtonSize("large", 40px, 24px);
@include MatIconButtonSize("medium", 32px, 19px);
@include MatIconButtonSize("small", 26px, 16px);
@include MatIconButtonSize("tiny", 20px, 12px);
@include MatIconSize("large", 24px);
@include MatIconSize("medium", 19px);
@include MatIconSize("small", 16px);
@include MatIconSize("tiny", 12px);
